.ForgotPassword{
    .reset-icon{
        background:#1bbd7e;
        border-radius: 50%;
        margin-bottom: 20px;
        padding: 12px;
        color: #fff;
    }

    .iconWrapper{
        cursor: pointer;
        margin: 30px 0px 10px 20px;
        span{
            display: block;
            font-size: 22px;
            margin-left: 5px;
            margin-top: -2px;
        }
        &:hover{
            text-decoration: underline;
        }
    }
    
}

.ResetPassword{
    .reset-icon{
        background: #1bbd7e;
        border-radius: 50%;
        margin-bottom: 20px;
        padding: 15px;
        color: #fff;
    }
    .iconWrapper{
        cursor: pointer;
        margin: 30px 0px 10px 20px;
        span{
            display: block;
            font-size: 22px;
            margin-left: 5px;
            margin-top: -2px;
        }
        &:hover{
            text-decoration: underline;
        }
    }
    .password-check-list{
        li{
            align-items: center;
        }
        margin-top: -10px;
    }
}