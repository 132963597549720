.Users{
    .expense-selector-list{
        width: 200px;
        margin-right: 50px;
        margin-top:-10px;
    }
    .edit-button{
        display:block;
        margin-left: 30px;
        padding:5px 30px 5px 30px;

    }
    .edit-button-col{
        padding: 15px;
    }

}

.UserForm{
    .expense-selector{
        width: 200px;
    }
    .update-btn{
        svg{
            color: #fff;
        }
        svg path{
            stroke:#fff;
        }
    }
    margin-bottom: 50px!important;
}

.UsersPopup{
    .close-icon{
        margin-left: auto;
        margin: 10px 10px auto auto;
    }
}