.Orders{
    .completed{
        color:green;
    }
    .completed:hover{
        color:#054705 ;
    }
    .completed-selected{
        color: #054705;
    }
    .completed-selected:hover{
        color: #054705;
    }
    .order-status-select{
        margin-right: 20px;
        width:100%;
        margin-top: -16px;
    }
}