body{
    background-image: url('./assets/images/background.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    overflow-x: hidden;
    
}

.MuiButton-containedPrimary{
    background: #333333!important;
}