.appbar{
    height: auto;
    position: fixed;
    background: rgba(247,247,247,255)!important;
    .icon-wrapper-logout{
        display: flex;
        color: #222;
        margin-top: 15px;
        margin-right: 10px;
        width:100px;
        .logoutIcon{
            color: #fff!important;
            background:#cc0000;
            border: solid 2px #cc0000;
            padding:4px 5px ;
            border-radius: 4px;
            cursor:pointer;
            box-shadow: #cc0000 0px 1px 4px;
        }
        .logoutIcon::after{
            border-bottom:none;
        }
        .logoutIcon:hover{
            background:#b30000;
            box-shadow: #b30000 0px 1px 4px;
        }
    }
    a{
        text-decoration: none;
        color: inherit;
    }
    .logo-wrapper{
        cursor:pointer;
        height: 70px;
        img{
            height: 100%;
        }
    }
    .left{
        color:#222;
        display: flex;
        align-items: center;
        flex:none;
        margin-right: 15px;
    }
    .right{
        display: flex;
        margin-top: 10px;
        margin-bottom: 10px;
        flex-wrap: wrap;
        align-items: center;
        color: #222!important;
        .nav-item{
            padding-right: 15px;
            margin-bottom: 5px;
        }
        .nav-link,.active{
            cursor:pointer;
            font-size: 18px;
            font-weight: bold;
        }
         .active::after,.nav-link::after{
            content:'';
            opacity: 0;
            display: block;
            border-bottom:solid 3px #222;
            max-width: 0px!important;
            transition:  0.5s linear 0s max-width;
        }
        .nav-link:hover{
            &::after{
                opacity: 1;
                max-width: 150px!important;
            }
        }
        .active{
            &::after{
                
                opacity: 1;
                max-width: 200px!important;
            }
        }
        .nav-drop-down{
            display: none;
            z-index: 10;
            position: absolute;
            box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
            background: #f2f2f2;
            padding: 4px;
            a{
                padding: 7px;
                padding-bottom: 4px;
                font-weight: bold;
                font-size: 15.5px;
            }

        }
        .products-form-dropdown,.list-dropdown{
            &:hover{
                .nav-drop-down{
                    display: flex ;
                    flex-direction: column;
                }
                
            }
        }
    }
}