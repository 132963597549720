
.MuiDataGrid-panel{
    transform:translate3d(30.8px, 125.6px, 0px)!important;
  }

  .ProductList{

    $white-shade:#e6e6e6;
    .product-list-grid{
      border-top: none;
    }
    .MuiDataGrid-main{
      margin: 10px;
      margin-bottom: 0;
      margin-top: 0;
      box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
      background: #fff;

    }
    .MuiDataGrid-footerContainer{
      margin-left: 10px;
      margin-right: 10px;
    }
    .MuiDataGrid-main , .MuiDataGrid-columnHeaders{
      padding:1;
    }
    .MuiDataGrid-overlay{
      position: absolute;
      height: auto;
      top:40px;
    }

    
    .MuiDataGrid-row:hover{
      cursor:pointer;
      color:#25a7da;
    }
    .MuiDataGrid-cell{
      padding: 5px 10px!important;
    }
    .MuiDataGrid-columnHeader{
      background:#262626;
      color:$white-shade;
      .MuiDataGrid-columnHeaderTitle{
        font-weight:bold;
      }
      .MuiCheckbox-root svg path{
        color: $white-shade;
      }
      .MuiDataGrid-sortIcon{
        color:#fff ;
      }
    }

    .MuiDataGrid-cell:focus,.MuiDataGrid-columnHeader:focus ,.MuiDataGrid-cell:focus-within ,.MuiDataGrid-columnHeader:focus-within  {
        outline:none!important;
    }
    .MuiDataGrid-toolbarContainer{
      display:flex;
      justify-content:space-between;
      button{
        margin:0 25px 0 0px;
      }
      // .MuiFormControl-root{
      //   width:50%
      // } 
    }

    .custom-toolbar{
      .right{
        .search{
          width: 450px;
          margin-right: 10px;
        }
      }
      .left{
        width: 100%;
        max-height: 200px;
        margin-left: 8px;
      }
    }

    .status-btns-wrapper{
      button{
        margin-right:13px;
      }
    }
  }