.Expenses{
    .inwards{
        color:green;
    }
    .inwards:hover{
        color:#054705 ;
    }
    .inwards-selected{
        color: #054705;
    }
    .inwards-selected:hover{
        color: #054705;
    }
    .outwards{
        color:#ff3333;
    }
    .outwards:hover{
        color:#e60000 ;
    }
    .outwards-selected{
        color: #e60000;
    }
    .outwards-selected:hover{
        color: #e60000;
    }
    .expense-selector-list{
        width: 200px;
        margin-right: 50px;
        margin-top:-10px;
    }
    .edit-button{
        display:block;
        margin-left: 30px;
        padding:5px 30px 5px 30px;
        margin-top: 12px;
        margin-bottom: 12px;
    }

}

.ExpenseForm{
    .expense-selector{
        width: 200px;
    }
    .update-btn{
        svg path{
            stroke:#fff;
        }
    }
    margin-bottom: 50px!important;
}

.ExpensesPopup{
    .close-icon{
        margin-left: auto;
        margin: 10px 10px auto auto;
    }
}