.ItemList{
    .edit-button{
        display:block;
        margin-left: 30px;
        padding:5px 30px 5px 30px;
    }

    
}

.ItemPopup{
    h4{
        margin-top:25px;
    }
    
}