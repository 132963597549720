.home-container{
    overflow: hidden;
    margin-top: 2%;

    .home-card-media{
        height: 200px;
    }

    .half{
        height: 150px;
    }

    .home-card:hover{
        opacity: 0.9;
        cursor:pointer;
        box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
        }
}

