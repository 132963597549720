.ProductsForm{
    padding-left:70px;
    padding-right:70px;
    .paper{
        overflow-y: auto;
        margin: 30px 0 auto 0;
        width: 100%;
        max-height: 80vh;
    }
    .form-btn{
        margin-right:40px;
        transition: ease-in-out 0.2s;
    }
    .form-btn:focus{
        outline: none;
    }
    .form-btn:hover{
        scale:1.1
    }
    .form-grid{
        padding: 30px 80px 30px 80px;
        margin-bottom: 20px;
    }
    .item-grid{
        padding: 0px 0px 0px 40px;  
        margin-top: 20px;
    }
    .icon-button{
        color:#fff;
        background:#2e7d32;
        padding:5px;
        border-radius:4px;
        padding-right:10px;
        display:flex;
        align-items:flex-start;
    }
    .icon-button:hover{
        background: #225d25;
    }
    .delete-icon-grid{
        margin:20px 30px 0 0;
        color:#d32f2f;
        svg{
            cursor:pointer
        }
    }
    .MuiInputLabel-standard{
        font-size: 18px;
    }

    .add-row-btn-grid{
        margin-top:15px;
        padding-left:0px!important;
        margin-left:40px;
        width:auto;
    }
    .MuiFormHelperText-root{
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
    .MuiFormHelperText-root:hover{
        text-overflow: initial;
        overflow: auto;
        white-space: break-spaces;
        cursor:help;
    }
    .pdf-option{
        display: inline;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        border-radius: 5px;;
        padding-left:12px;
        padding-right:12px;
        background:red;
        color:white;
        letter-spacing: 0.7px;
        text-transform: capitalize;
        font-weight: bold;
        .checkbox{
            color: white;
        }
        .checkbox:checked{
            color: white;
        }

    }
}