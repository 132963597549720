.PartyTransactions{
    $buy-color:#a81919;
    $sell-color:#2eb42e;

    h3{
        color:rgba(15, 22, 87, 0.979);
        .header-img{
            margin-right: 8px;
        }
        padding-left: 30px;

    }
    .transaction-selector{
        margin-right:20px;
        width:200px;
        margin-top: -16px;
    }
    .row-class-buy{
        color:$buy-color;
        .MuiCheckbox-root svg{
            path{
              color: #222;  
            } 
        }
        .MuiCheckbox-root.Mui-checked svg{
            color:$buy-color;
        }
        &:hover {
            color: $buy-color!important;
        }
    }


    .row-class-sell{
        color:  $sell-color;

        .MuiCheckbox-root svg{
            path{
              color: #222;  
            } 
        }
        .MuiCheckbox-root.Mui-checked svg{
            color:$sell-color;
        }
        &:hover{
            color:  $sell-color !important;
        }

    }

    // .buy-selected{
    //     background: #ff4d4d!important;
    //     color:  $buy-color!important;
    // }
    // .sell-selected{
    //     background: #4dff4d!important;
    //     color:  $sell-color!important;
    // }

}