.page-header{
    h2{
        color:#222;
        span{
            background: white;
            border:15px inset #f2f2f2;
            padding: 5px 20px 5px 20px;
            margin-left: -20px;
        }
        .header-img{
            margin-right: 8px;
        }
        font-weight: 900;
    }
    .divider{
        border-top: double 7px #8c8c8c;
        margin-top: -80px;
        margin-bottom: 80px;
        z-index: -10;

    }
    .backIcon{
        margin: 20px;
        margin-bottom: 0;
        margin-right: 0;
        cursor: pointer;
    }
}