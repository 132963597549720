
.hover-button-wrap{
  width: 100%;
  button{
    width: 100%;
    display: inline-block;
    position: relative;
    color: #222;
    cursor: pointer;
    background: rgba(0,0,0,0.09);
  }
  span{
    display: block;
    padding: 10px 15px;
    font-weight: 900;
    font-size:large;
    width: 100%;
  }
}
    