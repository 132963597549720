

#oopss {
  text-align: center;
  margin-bottom: 50px;
  font-weight: 400;
  font-size: 20px;
  position: fixed;
  width: 100%;
  height: 100%;
  line-height: 1.5em;
  z-index: 5;
  left: 0px;
}

#error-text {
  top: 30%;
  position: relative;
  font-size: 40px;
  color: #eee;
}

#error-text a {
  text-decoration: none;  
  color: #eee;
}


#error-text p {
  color: #222;
  margin: 70px 0 0 0;
}

#error-text i {
  margin-left: 10px;
}

#error-text p.hmpg {
  margin: 40px 0 0 0;
}

#error-text span {
  position: relative;
  background: #222;
  color: #fff;
  font-size: 300%;
  padding: 0 20px;
  border-radius: 5px;
  font-weight: bolder;
  transition: all .5s;
  cursor: pointer;
  margin: 0 0 40px 0;
}



#error-text span:after {
  top: 100%;
  left: 50%;
  border: solid transparent;
  content: '';
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(136, 183, 213, 0);
  border-top-color: #222;
  border-width: 7px;
  margin-left: -7px;
}

/* CSS Error Page Responsive */

.back:active {
  -webkit-transform: scale(0.95);
  -moz-transform: scale(0.95);
  transform: scale(0.95);
  background: #f53b3b;
  color: #222;
}

.back:hover {
  background: #f2f2f2;
  color: #222!important;
  border: 2px solid #222;
}

.back {
  text-decoration: none;
  background: #222;
  color: #fff;
  padding: 10px 20px;
  font-size: 20px;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  border-radius: 3px;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  transform: scale(1);
  transition: all 0.1s ease-in-out;
}