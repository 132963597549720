.ProductsForm{
    @media screen and (max-width:1280px) {
        &{
            padding-left: 25px;
            padding-right: 25px;
        }

        .add-row-btn-grid{
            margin-left: 30px;
        }
    }
}