.PartyList{
    .edit-button{
        display:block;
        margin-left: 30px;
        padding:5px 30px 5px 30px;
    }
}

.PartyPopup{
    h4{
        margin-top: 25px;
    }
}