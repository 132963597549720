.dateRange{
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    color: #222!important;
    margin-right: 25px;
    margin-top:-2px
}
.rs-picker-toolbar-ranges{
    max-width: 500px!important;
}

